import { SlInput } from '@shoelace-style/shoelace';

const DeleteAccount = () => {
	return {
		warningState: false,
		deleteState: false,
		init() {
			const { dialog, open, form, input, phrase, submit } = this.$refs;
			this.form = form;
			this.input = input;

			if (!dialog) return;
			open.addEventListener('click', dialog.show.bind(dialog));

			if (!input) return;
			input.addEventListener('sl-input', ({ target }) => {
				if (!(target instanceof SlInput)) return;
				submit.toggleAttribute('disabled', phrase.innerText !== target.value);
			});
		},
		reset() {
			this.warningState = false;
			this.deleteState = false;

			this.form.reset();
			this.input.value = '';
		},
	};
};

export default DeleteAccount;
