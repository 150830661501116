import fetch from '@/client/api/Fetcher';
import { justValidate } from '@/client/lib/JustValidate';
import { toast } from '@/client/plugins/@toaster';
import { listener, refs } from '@/client/utils/dom';
import { SlDetails, type SlShowEvent } from '@shoelace-style/shoelace';

const EnableTOTP = () => {
	return {
		url_generate: '/2fa/generate',
		url_enable: '/2fa/enable',
		validator: null,
		loading: false,
		secret: null,
		qrCode: null,
		fields: Array.from(refs("input[type='number']")),
		emptyChar: ' ',
		init() {
			const { open, dialog, form, apple, google, group } = this.$refs;
			this.open = open;
			this.dialog = dialog;
			this.form = form;
			this.apple = apple;
			this.google = google;
			this.group = group;

			if (!form) return;

			this.focusOnFirstInput();
			this.initializeOTP();
			this.validator = justValidate(this.form).onSuccess((event: SubmitEvent) => {
				event.preventDefault();
				this.submit();
			});

			this.details();
			this.apps();
			this.assign();

			if (!dialog) return;
			open.addEventListener('click', dialog.show.bind(dialog));
		},
		details() {
			this.group.addEventListener('sl-show', (event: SlShowEvent) => {
				const { target } = event;
				if (target instanceof SlDetails && target.localName === 'sl-details') {
					[...this.$refs.group.querySelectorAll('sl-details')].map(
						(details: SlDetails) => (details.open = event.target === details),
					);
				}

				this.focusOnFirstInput();
			}); // close all other details when one is shown

			[...this.$refs.group.querySelectorAll('sl-details')].map((details: SlDetails) => {
				details.addEventListener('sl-after-hide', () => {
					this.clearInputs();
					this.focusOnFirstInput();
				});
			});
		},
		apps() {
			const appleUri = 'https://apps.apple.com/fr/app/google-authenticator/id388497605';
			this.apple.addEventListener('click', () => (location.href = appleUri));

			const googleUri =
				'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
			this.google.addEventListener('click', () => (location.href = googleUri));
		},
		async generate() {
			if (this.secret && this.qrCode) {
				return { secret: this.secret, qrCode: this.qrCode };
			}

			try {
				const response = await fetch.Get(this.url_generate);
				const { secret, qrCode } = response.data;
				this.secret = secret;
				this.qrCode = qrCode;

				return { secret, qrCode };
			} catch (error) {
				if (env === 'development') {
					console.error(error);
				}
			}
		},
		async assign() {
			const { secret, qrCode } = await this.generate();
			this.$refs.qrCode.value = qrCode;
			this.$refs.secretKey.textContent = secret;
			this.$refs.copyButton.setAttribute('value', secret);

			listener(this.$refs.copyButton, 'sl-copy', () =>
				toast('Clé secrète copiée avec succès !', {
					description: "Copiez cette clé secrète dans votre application d'authentification.",
					type: 'success',
				}),
			);

			listener(this.$refs.copyButton, 'sl-error', () =>
				toast('Une erreur est survenue !', {
					description: 'Impossible de copier la clé secrète. Veuillez réessayer plus tard.',
					type: 'danger',
				}),
			);
		},
		async submit() {
			this.loading = true;
			await this.$nextTick();

			if (this.fields.every((input: HTMLInputElement) => input.value === '')) {
				toast('Vérification en deux étapes', {
					description: 'Veuillez entrer le code de vérification !',
					type: 'warning',
				});

				this.focusOnFirstInput();
				this.loading = false;
				return;
			}

			try {
				const { secret } = await this.generate();
				await fetch.Post(this.url_enable, {
					token: this.fields
						.map((input: HTMLInputElement) => input.value || this.emptyChar)
						.join(''),
					secret,
				});

				window.location.reload();
			} catch (error) {
				if (env === 'development') {
					console.error(error);
				}

				this.focusOnFirstInput();
				this.clearInputs();

				toast('Vérification en deux étapes', {
					description: 'Oups, le code saisi est incorrect !',
					type: 'danger',
				});
			} finally {
				this.loading = false;
				await this.$nextTick();
			}
		},
		focusOnFirstInput() {
			this.fields[0]?.focus();
		},
		initializeOTP() {
			const checkAndSubmit = this.debounce(() => {
				if (this.fields.every(({ value }) => value.trim())) {
					this.$refs.submit.click();
				}
			}, 300);

			this.fields.forEach((input: HTMLInputElement, idx: number) => {
				listener(input, 'input', () => {
					input.value = input.value.replace(/\D/g, '').slice(0, 1);
					if (input.value && this.fields[idx + 1]) {
						this.fields[idx + 1].focus();
					}
					checkAndSubmit();
				});

				listener(input, 'keydown', (event: Event) => {
					const { key, target } = event as KeyboardEvent;
					const currentInput = target as HTMLInputElement;
					const actions = new Map<string, () => void>([
						['Backspace', () => this.handleBackspace(currentInput, idx)],
						['Delete', () => this.handleDelete(idx)],
						['ArrowLeft', () => this.handleArrowLeft(idx)],
						['ArrowRight', () => this.handleArrowRight(idx)],
					]);

					actions.get(key)?.();
				});

				listener(input, 'paste', (event: Event) => {
					const clipboard = event as ClipboardEvent;
					clipboard.preventDefault();

					const pasteData = clipboard.clipboardData?.getData('text').replace(/\D/g, '');
					if (!pasteData) return;

					const length = Math.min(pasteData.length, this.fields.length);
					for (let i = 0; i < length; i++) {
						this.fields[i].value = pasteData[i];
					}

					this.fields[length - 1]?.focus();
					checkAndSubmit();
				});

				listener(input, 'focus', () => input.select());
				listener(input, 'click', () => input.select());
			});
		},
		handleBackspace(currentInput: HTMLInputElement, idx: number) {
			if (!currentInput.value && idx > 0) {
				const prevInput = this.fields[idx - 1];
				prevInput.value = '';
				prevInput.focus();
			}
		},
		handleDelete(idx: number) {
			if (idx < this.fields.length - 1) {
				for (let i = idx; i < this.fields.length - 1; i++) {
					this.fields[i].value = this.fields[i + 1].value;
				}
				const lastInput = this.fields.at(-1);
				if (lastInput) lastInput.value = '';
			}
		},
		handleArrowLeft(idx: number) {
			if (idx > 0) {
				this.fields[idx - 1].focus();
			}
		},
		handleArrowRight(idx: number) {
			if (idx < this.fields.length - 1) {
				this.fields[idx + 1].focus();
			}
		},
		clearInputs() {
			for (const input of this.fields) {
				input.value = '';
			}
			this.focusOnFirstInput();
		},
		debounce(func: (...args: any[]) => void, wait: number) {
			let timeout: number | undefined;
			return (...args: any[]) => {
				clearTimeout(timeout);
				timeout = window.setTimeout(() => func.apply(this, args), wait);
			};
		},
	};
};

export default EnableTOTP;
