import fetch from '@/client/api/Fetcher';
import { IsOutsideElement } from '@/client/hooks/IsOutsideElement';
import { justValidate } from '@/client/lib/JustValidate';
import { ConfirmPasswordRule } from '@/client/lib/JustValidate/rules/ConfirmPassword';
import { RequiredRule } from '@/client/lib/JustValidate/rules/Required';
import { StrongPasswordRule } from '@/client/lib/JustValidate/rules/StrongPassword';
import { toast } from '@/client/plugins/@toaster';
import { serialize } from '@shoelace-style/shoelace';

const UpdatePassword = () => {
	return {
		url: '/mot-de-passe/update',
		validator: null,
		loading: false,
		password: '',
		strength: '',
		fields: {
			actual_password: "[name='actual-password']",
			new_password: "[name='new-password']",
			confirm_password: "[name='confirm-password']",
		},
		init() {
			const { container, formPasswordUpdate, checkStrengthDiv, update } = this.$refs;
			this.container = container;
			this.formPasswordUpdate = formPasswordUpdate;
			this.checkStrengthDiv = checkStrengthDiv;
			this.update = update;

			const { actual_password, new_password, confirm_password } = this.fields;
			this.validator = justValidate(this.formPasswordUpdate)
				.addField(actual_password, RequiredRule)
				.addField(new_password, StrongPasswordRule)
				.addField(confirm_password, ConfirmPasswordRule(new_password))
				.onSuccess((event: SubmitEvent) => {
					event.preventDefault();
					this.submitFormData();
				})
				.onFail(() => setTimeout(() => this.reset(), 3000));

			IsOutsideElement(this.container, () => {
				this.validator.clearErrors();
				this.formPasswordUpdate.reset();
				this.strength = '';
			});

			document.querySelector(this.fields.new_password).addEventListener('sl-focus', () => {
				this.checkStrengthDiv.classList.remove('hidden');
			});

			document.querySelector(this.fields.new_password).addEventListener('sl-blur', () => {
				this.checkStrengthDiv.classList.add('hidden');
			});
		},
		async submitFormData() {
			this.loading = true;
			await this.$nextTick();

			try {
				const {
					data: { message, updatedAt },
				} = await fetch.Put(this.url, {
					form: serialize(this.formPasswordUpdate),
				});

				if (this.update) {
					this.update.textContent = updatedAt;
				}

				toast(`Modifié le ${updatedAt} !`, {
					description: message,
					type: 'success',
				});
			} catch (error) {
				if (env === 'development') {
					console.error(error);
				}

				const { title, message, type } = error.response.data;
				toast(title, {
					description: message,
					type: type || 'danger',
				});
			} finally {
				this.loading = false;
				this.reset();
			}
		},
		reset() {
			this.validator.clearErrors();
			this.formPasswordUpdate.reset();
		},
		checkPasswordStrength() {
			if (this.password.length === 0) {
				this.strength = '';
				return;
			}

			this.strength = 'faible';

			const hasLowerCase: boolean = /[a-z]/.test(this.password);
			const hasUpperCase: boolean = /[A-Z]/.test(this.password);
			const hasNumber: boolean = /\d/.test(this.password);
			const hasSpecialChar: boolean = /[!@#$%^&*(),.?':{}|<>]/.test(this.password);

			const passedChecks = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialChar].filter(
				Boolean,
			).length;

			if (this.password.length >= 9) {
				if (passedChecks === 4 || this.password.length >= 12) {
					this.strength = 'excellent';
				} else if (passedChecks >= 3) {
					this.strength = 'bon';
				} else if (passedChecks >= 2) {
					this.strength = 'moyen';
				}
			}
		},
	};
};

export default UpdatePassword;
