export const IsOutsideElement = (
	element: HTMLElement,
	callback: () => void,
): void => {
	document.addEventListener('click', (event: MouseEvent) => {
		const isOutsideElement: boolean =
			element && !element.contains(event.target as Node);

		if (isOutsideElement) {
			callback();
		}
	});
};
