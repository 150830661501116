import dayjs from 'dayjs';
import { Rules } from 'just-validate';
import 'dayjs/locale/fr.js';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.locale('fr');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export const DatePassedRule = [
	{ rule: Rules.Required },
	{
		validator: (value: string | boolean): boolean => {
			if (typeof value !== 'string') {
				return false;
			}

			const inputDate = dayjs(value, 'DD/MM/YYYY');
			const today = dayjs();
			const twoYearsAgo = dayjs().subtract(2, 'year');

			return (
				today.isSame(inputDate, 'day') ||
				(!today.isBefore(inputDate, 'day') && inputDate.isBetween(twoYearsAgo, today, 'day', '[]'))
			);
		},
		errorMessage: 'La date saisie ne doit pas être dans le futur, ou trop ancienne (+2ans).',
	},
];
