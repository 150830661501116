import axios, {
	type AxiosInstance,
	type AxiosRequestConfig,
	type AxiosResponse,
} from 'axios';

type APIConfig = {
	skeleton?: string;
	container?: HTMLElement;
};

export class API {
	private axiosInstance: AxiosInstance;

	constructor(baseURL: string) {
		this.axiosInstance = axios.create({ baseURL });
	}

	private async withLoader<T>(
		request: () => Promise<AxiosResponse<T>>,
		config?: APIConfig,
	): Promise<AxiosResponse<T>> {
		if (config?.skeleton && config?.container) {
			config.container.innerHTML = config.skeleton;
		}

		try {
			const response = await request();
			if (config?.container) {
				config.container.innerHTML = '';
			}
			return response;
		} catch (error) {
			if (config?.container) {
				config.container.innerHTML = '';
			}
			return await Promise.reject(error);
		}
	}

	public async Get<T>(
		url: string,
		config?: AxiosRequestConfig,
		loaderConfig?: APIConfig,
	): Promise<AxiosResponse<T> | any> {
		return this.withLoader(
			() => this.axiosInstance.get(url, config),
			loaderConfig,
		);
	}

	public async Post<T>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
		loaderConfig?: APIConfig,
	): Promise<AxiosResponse<T> | any> {
		return this.withLoader(
			() => this.axiosInstance.post(url, data, config),
			loaderConfig,
		);
	}

	public async Put<T>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
		loaderConfig?: APIConfig,
	): Promise<AxiosResponse<T> | any> {
		return this.withLoader(
			() => this.axiosInstance.put(url, data, config),
			loaderConfig,
		);
	}

	public async Patch<T>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
		loaderConfig?: APIConfig,
	): Promise<AxiosResponse<T> | any> {
		return this.withLoader(
			() => this.axiosInstance.patch(url, data, config),
			loaderConfig,
		);
	}

	public async Delete<T>(
		url: string,
		config?: AxiosRequestConfig,
		loaderConfig?: APIConfig,
	): Promise<AxiosResponse<T> | any> {
		return this.withLoader(
			() => this.axiosInstance.delete(url, config),
			loaderConfig,
		);
	}
}

type FetcherProps = {
	baseURL: string;
};

const fetcher = ({ baseURL }: FetcherProps): API => {
	return new API(baseURL);
};

const fetch = fetcher({ baseURL: '/api' });

export default fetch;
