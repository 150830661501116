import { startLoading, stopLoading } from '@/client/api/manager/LoadingManager';
import { up } from 'up-fetch';

export const UpFetch = up(fetch, () => ({
	baseUrl: '/api',
	timeout: 30000,
	parseRejected: async (response: Response) => await response.json(),
	onRequest: () => startLoading(),
	onSuccess: () => stopLoading(),
	onError: () => stopLoading(),
}));
