import type { SlButton, SlIconButton } from '@shoelace-style/shoelace';

type Selector = SlButton | SlIconButton | HTMLButtonElement | HTMLElement;
const setDisabledState = (element: Selector | null | undefined, isDisabled: boolean): void => {
	if (!element) {
		console.warn('setDisabledState: Element is null or undefined.');
		return;
	}
	element.toggleAttribute('disabled', isDisabled);
};

export default setDisabledState;
