export const Debounce = <T>(
	fn: (arg: T) => void,
	delay: number,
): ((arg: T) => void) => {
	let timer: ReturnType<typeof setTimeout>;
	return (arg: T): void => {
		clearTimeout(timer);
		timer = setTimeout(() => fn(arg), delay);
	};
};
