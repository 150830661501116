import fetch from '@/client/api/Fetcher';
import { justValidate } from '@/client/lib/JustValidate';
import { OtpCodeRule } from '@/client/lib/JustValidate/rules/OtpCode';
import { toast } from '@/client/plugins/@toaster';
import { serialize } from '@shoelace-style/shoelace';

const DisableTOTP = () => {
	return {
		url_validate: '/2fa/validate',
		url_disable: '/2fa/disable',
		validator: null,
		loading: false,
		init() {
			const { form } = this.$refs;
			this.form = form;

			if (!form) return;

			this.validation();
		},
		validation() {
			this.validator = justValidate(this.form);
			this.validator
				.addField("[name='form-code']", OtpCodeRule)
				.onSuccess(async (event: SubmitEvent) => {
					event.preventDefault();

					this.loading = true;
					await this.$nextTick();

					try {
						const { 'form-code': token } = serialize(this.$refs.form);
						await fetch.Post('/2fa/validate', { token });
						await fetch.Post('/2fa/disable');
						window.location.reload();
					} catch (error) {
						if (env === 'development') {
							console.error(error);
						}

						toast('Vérification en deux étapes', {
							description: 'Oups, une erreur est survenue !',
							type: 'danger',
						});
					} finally {
						this.loading = false;
						await this.$nextTick();
					}
				})
				.onFail(() =>
					setTimeout((): void => {
						this.reset();
					}, 3000),
				);
		},
		reset() {
			this.validator.clearErrors();
			this.form.reset();
		},
	};
};

export default DisableTOTP;
