import { Rules } from 'just-validate';

export const OtpCodeRule = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.Number,
	},
	{
		rule: Rules.CustomRegexp,
		value: /^\d{1,6}$/,
	},
];
