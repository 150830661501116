import { UpFetch } from '@/client/api/UpFetch';
import { justValidate } from '@/client/lib/JustValidate';
import { EmailRule } from '@/client/lib/JustValidate/rules/Email';
import { toast } from '@/client/plugins/@toaster';
import { serialize } from '@shoelace-style/shoelace';

const Newsletter = () => {
	return {
		url: '/newsletter/subscribe',
		validator: null,
		loading: false,
		fields: {
			email: "[name='email']",
		},
		init() {
			const { email } = this.fields;
			this.validator = justValidate(this.$refs.form);
			this.validator
				.addField(email, EmailRule)
				.onSuccess((event: SubmitEvent) => {
					event.preventDefault();
					this.submitForm();
				})
				.onFail(() => setTimeout(() => this.resetForm(), 3000));
		},
		async submitForm() {
			this.loading = true;
			await this.$nextTick();

			try {
				const { email } = serialize(this.$refs.form);
				const { title, message, type } = await UpFetch(this.url, {
					method: 'POST',
					body: { email },
				});

				toast(title, {
					description: message,
					type,
				});
			} catch (error) {
				if (env === 'development') {
					console.error(error);
				}

				const { title, message, type } = error.response.data;

				toast(title, {
					description: message,
					type,
				});
			} finally {
				this.loading = false;
				this.resetForm();
			}
		},
		resetForm() {
			this.validator.clearErrors();
			this.$refs.form.reset();
			this.$refs.input.value = '';
		},
	};
};

export default Newsletter;
