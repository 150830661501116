let loadingCount = 0;
const listeners: Set<(loading: boolean) => void> = new Set();

const notify = () => {
	const isLoading = loadingCount > 0;
	for (const cb of listeners) cb(isLoading);
};

export const startLoading = () => {
	loadingCount++;
	notify();
};

export const stopLoading = () => {
	loadingCount = Math.max(loadingCount - 1, 0);
	notify();
};

export const isLoading = (): boolean => loadingCount > 0;

export const onLoadingChange = (cb: (loading: boolean) => void) => {
	listeners.add(cb);
	cb(isLoading());
	return () => listeners.delete(cb);
};
