import { Rules } from 'just-validate';
import { ValidateImei } from '@/client/hooks/ValidateImei';

const regex: RegExp = /^\d{15}$/;

export const ImeiRule = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.MinLength,
		value: 15,
		errorMessage: 'Veuillez saisir un IMEI valide.',
	},
	{
		rule: Rules.MaxLength,
		value: 15,
		errorMessage: 'Veuillez saisir un IMEI valide.',
	},
	{
		validator: (value: string | boolean): boolean => {
			if (typeof value === 'string' && regex.test(value)) {
				return ValidateImei(value);
			}

			return false;
		},
		errorMessage: 'Veuillez saisir un IMEI valide.',
	},
];
