import JustValidate from 'just-validate';
import { JustValidateLocales } from './Locales';
import { JustValidateOptions } from './Options';

const LOCALE = 'fr';
export const justValidate = (selector: string | Element): JustValidate => {
	const instance = new JustValidate(
		selector,
		JustValidateOptions,
		JustValidateLocales,
	);
	instance.setCurrentLocale(LOCALE);

	return instance;
};
