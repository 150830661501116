import Clusterize from 'clusterize.js';

const clusterize = () => {
	const clusterize = new Clusterize({
		scrollId: 'scrollArea',
		contentId: 'contentArea',
	});
	clusterize.refresh();

	return clusterize;
};

export default clusterize;
