import { Rules } from 'just-validate';

export const StrongPasswordRule = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.MinLength,
		value: 9,
		errorMessage:
			'Le mot de passe doit contenir au moins neuf caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
	},
	{
		rule: Rules.StrongPassword,
	},
];
