import { Rules } from 'just-validate';

const LOCALE = 'fr';
export const JustValidateLocales = [
	{
		key: Rules.Required,
		dict: { [LOCALE]: 'Ce champ est obligatoire.' },
	},
	{
		key: Rules.Email,
		dict: { [LOCALE]: 'Veuillez saisir une adresse e-mail valide.' },
	},
	{
		key: Rules.MinLength,
		dict: {
			[LOCALE]: 'Le mot de passe doit contenir au moins :value caractères.',
		},
	},
	{
		key: Rules.MaxLength,
		dict: {
			[LOCALE]: 'Le mot de passe doit contenir maximum :value caractères.',
		},
	},
	{
		key: Rules.Password,
		dict: {
			[LOCALE]:
				'Le mot de passe doit contenir au moins neuf caractères, au moins une lettre et un chiffre.',
		},
	},
	{
		key: Rules.Number,
		dict: { [LOCALE]: 'Veuillez saisir le code.' },
	},
	{
		key: Rules.Integer,
		dict: { [LOCALE]: 'Veuillez saisir un nombre entier.' },
	},
	{
		key: Rules.MaxNumber,
		dict: { [LOCALE]: 'Le code doit contenir maximum :value chiffres.' },
	},
	{
		key: Rules.MinNumber,
		dict: { [LOCALE]: 'Le code doit contenir minimum :value chiffres.' },
	},
	{
		key: Rules.StrongPassword,
		dict: {
			[LOCALE]:
				'Le mot de passe doit contenir au moins neuf caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
		},
	},
	{
		key: Rules.CustomRegexp,
		dict: { [LOCALE]: 'Veuillez saisir le code qui est de 6 chiffres.' },
	},
	{
		key: Rules.MinFilesCount,
		dict: { [LOCALE]: 'Veuillez ajouter au moins :value fichier.' },
	},
	{
		key: Rules.MaxFilesCount,
		dict: { [LOCALE]: 'Veuillez ajouter maximum :value fichier.' },
	},
	{
		key: Rules.Files,
		dict: { [LOCALE]: 'Veuillez ajouter un fichier valide.' },
	},
];
