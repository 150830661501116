import fetch from '@/client/api/Fetcher';
import { PromiseWait } from '@/client/hooks/PromiseWait';
import { justValidate } from '@/client/lib/JustValidate';
import { RequiredRule } from '@/client/lib/JustValidate/rules/Required';
import { toast } from '@/client/plugins/@toaster';
import { serialize } from '@shoelace-style/shoelace';
import { type ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';

const PhoneNumber = () => {
	return {
		url_data: '/telephone/data',
		url_update: '/telephone/update',
		validator: null,
		loading: false,
		init() {
			const { container, form, select, actual } = this.$refs;
			this.container = container;
			this.form = form;
			this.select = select;
			this.actual = actual;

			this.validation();
			this.actualNumber();
		},
		validation() {
			this.validator = justValidate(this.form);
			this.validator
				.addField("[name='phone-number']", RequiredRule)
				.onSuccess(async (event: SubmitEvent) => {
					event.preventDefault();

					this.loading = true;
					await this.$nextTick();

					const { 'phone-number': phoneNumber, 'country-code': countryCode } = serialize(
						this.$refs.form,
					);

					const pn: ParsedPhoneNumber = parsePhoneNumber(phoneNumber as string, {
						regionCode: countryCode as string,
					});

					if (pn.valid) {
						try {
							const response = await fetch.Patch(this.url_update, {
								number: pn.number.e164,
								dial: `+${pn.countryCode}`,
							});
							const { title, message } = response.data;

							toast(title, {
								description: message,
								type: 'success',
							});

							await PromiseWait(3000);
							window.location.reload();
						} catch (error) {
							if (env === 'development') {
								console.error(error);
							}

							toast(error.response.data.title, {
								description: error.response.data.message,
								type: 'danger',
							});
						} finally {
							this.loading = false;
							await this.$nextTick();
						}
					} else {
						this.loading = false;
						await this.$nextTick();

						if (countryCode === '' || countryCode === null) {
							toast('Une erreur est survenue', {
								description: "L'indicatif du pays est nécessaire.",
								type: 'danger',
							});
						} else {
							toast('Une erreur est survenue', {
								description: 'Hmm, ce numéro ne semble pas tout à fait correct.',
								type: 'danger',
							});
						}
					}
				});
		},
		async actualNumber() {
			try {
				const { data } = await fetch.Get(this.url_data);
				this.actual.innerText = data;
			} catch (error) {
				if (env === 'development') {
					console.error(error);
				}

				this.actual.innerText = 'Une erreur est survenue !';
			}
		},
	};
};

export default PhoneNumber;
