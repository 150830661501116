import type { GlobalConfigInterface } from 'just-validate';

export const JustValidateOptions = {
	errorFieldCssClass: ['data-user-invalid'],
	errorLabelCssClass: ['data-user-label-invalid'],
	errorLabelStyle: {
		backgroundColor: '',
	},
	successFieldCssClass: ['data-user-valid'],
	successLabelCssClass: ['data-user-label-valid'],
	successLabelStyle: {
		backgroundColor: '',
	},
	focusInvalidField: true,
	lockForm: true,
	testingMode: false,
	validateBeforeSubmitting: true,
	submitFormAutomatically: false,
} as GlobalConfigInterface;
