import type { FieldsInterface } from 'just-validate';
import { Rules } from 'just-validate';

export const ConfirmPasswordRule = (password: string) => [
	{
		rule: Rules.Required,
	},
	{
		validator: (value: string | boolean, fields: FieldsInterface): boolean => {
			if (fields[password]?.elem) {
				return value === fields[password].elem.value;
			}

			return true;
		},
		errorMessage: 'Les mots de passe ne correspondent pas !',
	},
];
