import { Rules } from 'just-validate';

const regex: RegExp = /^0\d{9}$/;

export const PhoneNumberRule = [
	{
		rule: Rules.Required,
	},
	{
		validator: (value: string | boolean): boolean =>
			typeof value === 'string' && regex.test(value),
		errorMessage: 'Veuillez saisir un numéro de téléphone valide.',
	},
];
