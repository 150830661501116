import type { SlButton, SlIcon, SlIconButton } from '@shoelace-style/shoelace';

// 1. on récupère un élement dans le DOM
export const ref = <T extends HTMLElement>(
	selector: string,
	scope: ParentNode = document,
): T | null => scope.querySelector<T>(selector);

// 2. on récupère une liste d'éléments dans le DOM
export const refs = <T extends HTMLElement>(
	selector: string,
	scope: ParentNode = document,
): T[] => Array.from(scope.querySelectorAll<T>(selector));

// 3. on ajoute un événement sur un élément du DOM
type ShoelaceElements = SlButton | SlIcon | SlIconButton;
export const listener = (
	target:
		| string
		| HTMLElement
		| Document
		| NodeListOf<HTMLElement>
		| ShoelaceElements,
	event: string,
	handler: (event: Event) => void,
	options?: boolean | AddEventListenerOptions,
): void => {
	if (typeof target === 'string') {
		const element = ref<HTMLElement>(target);
		if (element) {
			element.addEventListener(event, handler, options);
		}
	} else if (target instanceof NodeList) {
		// biome-ignore lint/complexity/noForEach: <explanation>
		target.forEach((element) =>
			element.addEventListener(event, handler, options),
		);
	} else {
		target.addEventListener(event, handler, options);
	}
};
