import processIcon from 'public/assets/icons/heroicons/arrow-long-up.svg';
import retryIcon from 'public/assets/icons/heroicons/arrow-path.svg';
import undoIcon from 'public/assets/icons/heroicons/arrow-uturn-down.svg';
import trashIcon from 'public/assets/icons/heroicons/trash.svg';
import * as FilePond from 'filepond';
import type { FilePondBaseProps, FilePondDragDropProps, FilePondOptions } from 'filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

FilePond.registerPlugin(
	FilePondPluginFileEncode,
	FilePondPluginFileValidateSize,
	FilePondPluginFileValidateType,
);

export const filePondOptions: FilePondBaseProps | FilePondDragDropProps | FilePondOptions = {
	required: true,
	allowMultiple: false,
	storeAsFile: true,
	forceRevert: true,
	credits: false,
	checkValidity: true,
	dropValidation: true,
	allowReorder: true,
	ignoredFiles: ['.ds_store', 'thumbs.db', 'desktop.ini'],
	allowFileEncode: true,
	allowFileSizeValidation: true,
	maxFiles: 1,
	// labels
	labelFileLoading: 'Chargement ...',
	labelFileProcessing: 'En cours de téléchargement ...',
	labelFileProcessingComplete: 'Téléchargement terminé !',
	labelFileProcessingAborted: 'Téléchargement annulé !',
	labelTapToUndo: 'cliquez pour supprimer le fichier',
	labelTapToCancel: 'cliquez pour annuler',
	labelTapToRetry: 'cliquez pour réessayer',
	labelFileProcessingError: 'Erreur de téléchargement !',
	labelFileWaitingForSize: 'Calcul de la taille ...',
	labelFileSizeNotAvailable: 'Taille non disponible',
	// icons
	iconRemove: trashIcon,
	iconProcess: processIcon,
	iconRetry: retryIcon,
	iconUndo: undoIcon,
	// style
	styleButtonRemoveItemPosition: 'right',
};

if (module.hot) {
	module.hot.accept();
	module.hot.dispose((): void => {
		console.clear();
	});
}
