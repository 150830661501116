import { toast } from '@/client/plugins/@toaster';
import { Loader } from '@googlemaps/js-api-loader';
import { capitalize } from 'es-toolkit/string';
import { up } from 'up-fetch';

export const GoogleMapsLoader = new Loader({
	apiKey: 'AIzaSyDDTRxxg_fVTeG9Cs0Opd0Wr_MztWTjkMY',
	version: 'weekly',
	libraries: ['places'],
});

let GoogleMapsLibPromise: Promise<{
	Map: typeof google.maps.Map;
	ColorScheme: typeof google.maps.ColorScheme;
	AdvancedMarkerElement: typeof google.maps.marker.AdvancedMarkerElement;
	PinElement: typeof google.maps.marker.PinElement;
}> | null = null;

const LoadAllGoogleMapsLibraries = () => {
	if (!GoogleMapsLibPromise) {
		GoogleMapsLibPromise = Promise.all([
			GoogleMapsLoader.importLibrary('maps'),
			GoogleMapsLoader.importLibrary('core'),
			GoogleMapsLoader.importLibrary('marker'),
		]).then(([maps, core, marker]) => ({
			Map: maps.Map,
			ColorScheme: core.ColorScheme,
			AdvancedMarkerElement: marker.AdvancedMarkerElement,
			PinElement: marker.PinElement,
		}));
	}

	return GoogleMapsLibPromise;
};

export interface GoogleMapsOptions {
	theme: ThemeValue;
	element: HTMLDivElement;
	id: string;
	nom: string;
	glyph: string;
	environment: Env;
	baseUrl?: string;
	timeout?: number;
}

export const CreateGoogleMaps = async ({
	theme,
	element,
	id,
	nom,
	glyph,
	baseUrl = '/api',
	timeout = 30000,
}: GoogleMapsOptions): Promise<void> => {
	const upfetch = up(fetch, () => ({
		baseUrl,
		timeout,
	}));

	try {
		const { Map, ColorScheme, AdvancedMarkerElement, PinElement } =
			await LoadAllGoogleMapsLibraries();

		const { lat, lng } = await upfetch('/boutique/coordinates', {
			method: 'POST',
			body: { id },
		});

		const map: google.maps.Map = new Map(element, {
			mapId: 'd67debc9d71de61d',
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			zoom: 14,
			center: { lat, lng },
			disableDefaultUI: false,
			mapTypeControl: false,
			streetViewControl: false,
			scrollwheel: false,
			gestureHandling: 'none',
			zoomControl: false,
			clickableIcons: false,
			colorScheme: theme === 'dark' ? ColorScheme.DARK : ColorScheme.LIGHT,
		});

		const markerImage: HTMLImageElement = document.createElement('img');
		markerImage.src = glyph || `/assets/images/Pin${capitalize(theme)}.svg`;
		markerImage.alt = 'Position de cette boutique WeFix';

		const markerPin: google.maps.marker.PinElement = new PinElement({ glyph: markerImage });
		markerPin.scale = 1.5;
		markerPin.borderColor = 'var(--color-pin-border)';
		markerPin.background = 'var(--color-pin-background)';

		const marker: google.maps.marker.AdvancedMarkerElement = new AdvancedMarkerElement({ map });
		marker.position = { lat, lng };
		marker.content = markerPin.element;
	} catch (error) {
		if (env === 'development') {
			console.error(error);
		}

		toast(nom, {
			description: "Impossible d'afficher la carte !",
			type: 'danger',
		});

		element.innerHTML = `
			<div class='border-s border-dashed border-border h-full flex flex-col items-center justify-center bg-noise'>
				<sl-icon class='size-8 text-warning' library='heroicons' name='exclamation-triangle'></sl-icon>
				<h2 class='text-xl !font-medium mt-3'>
					Oups, une erreur est survenue !
				</h2>
				<p class='max-w-md text-center text-muted-foreground mt-1'>
					Impossible d'afficher la carte pour la boutique <strong>${nom}</strong> pour l'instant !
				</p>
			</div>
		`;
	}
};
