import { Rules } from 'just-validate';

export const EmailRule = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.Email,
	},
];
