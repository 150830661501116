import { GenerateUUID } from '@/client/hooks/GenerateUUID';
import { listener, ref } from '@/client/utils/dom';
import type { SlIcon } from '@shoelace-style/shoelace';

const TOAST_WIDTH = 400;
const VIEWPORT_OFFSET = 16;
const VISIBLE_TOASTS_AMOUNT = 3;
const GAP = 8;
const TOAST_LIFETIME = 3000;
const TIME_BEFORE_UNMOUNT = 200;
const DEFAULT_TYPE: ToastType = 'default';
const DEFAULT_POSITION: ToastPosition = 'bottom-right';

export const toast = (
	title: string,
	{ description, type = DEFAULT_TYPE, position = DEFAULT_POSITION }: ToastOptions = {},
) => {
	const wrapper = ref("[data-toaster='wrapper']") as HTMLDivElement;
	if (!wrapper) return;

	const list = ref("[data-toaster='list']") as HTMLOListElement;
	if (!list) {
		renderToaster(position);
		registerMouseOver();
	}

	updatePosition(position);

	show(title, { description, type, position });
};

const renderToaster = (position: ToastPosition) => {
	const toasterWrapper = ref("[data-toaster='wrapper']") as HTMLDivElement;
	if (!toasterWrapper) return;

	const ol: HTMLOListElement = document.createElement('ol');
	toasterWrapper.append(ol);

	const [y, x] = toasterWrapper.getAttribute('data-position')?.split('-') ?? position.split('-');

	ol.outerHTML = `<ol
        data-toaster='list'
        data-x-position='${x}'
        data-y-position='${y}'
        style='
            --toast-front-toast-height: 0px;
            --toast-offset: ${VIEWPORT_OFFSET}px;
            --toast-width: ${TOAST_WIDTH}px;
            --toast-gap: ${GAP}px'
        >
        </ol>`;
};

const updatePosition = (position: ToastPosition) => {
	const list = ref("[data-toaster='list']") as HTMLOListElement;
	if (!list) return;

	const [y, x] =
		list.parentElement?.getAttribute('data-position')?.split('-') ?? position.split('-');

	if (x !== list.getAttribute('data-x-position') || y !== list.getAttribute('data-y-position')) {
		list.setAttribute('data-x-position', x);
		list.setAttribute('data-y-position', y);

		for (const el of Array.from(list.children)) {
			el.setAttribute('data-x-position', x);
			el.setAttribute('data-y-position', y);
		}
	}
};

const registerMouseOver = () => {
	const list = ref("[data-toaster='list']") as HTMLOListElement;
	if (!list) return;

	const expandToasts = () => {
		for (const el of Array.from(list.children)) {
			const li = el as HTMLLIElement;
			if (li.getAttribute('data-expanded') === 'true') {
				continue;
			}
			li.setAttribute('data-expanded', 'true');
			clearRemoveTimeout(li);
		}
	};

	const collapseToasts = (): void => {
		for (const el of Array.from(list.children)) {
			const li = el as HTMLLIElement;
			if (li.getAttribute('data-expanded') === 'false') {
				continue;
			}
			li.setAttribute('data-expanded', 'false');
			registerRemoveTimeout(li);
		}
	};

	listener(list, 'mouseenter', expandToasts);
	listener(list, 'mouseleave', collapseToasts);
};

const show = (title: string, { description, type, position }: ToastOptions) => {
	const list = ref("[data-toaster='list']") as HTMLOListElement;
	if (!list) return;

	renderToast(list, title, { description, type, position });

	window.setTimeout(() => {
		const el = list.children[0] as HTMLLIElement;
		const height = el.getBoundingClientRect().height;

		el.setAttribute('data-mounted', 'true');
		el.setAttribute('data-initial-height', `${height}`);
		el.style.setProperty('--initial-height', `${height}px`);
		list.style.setProperty('--toast-front-toast-height', `${height}px`);

		refreshProperties();
		registerRemoveTimeout(el);
		closeToast(el);
		enableSwipeToDismiss(el);
	}, 16);
};

const renderToast = (
	list: HTMLElement,
	title: string,
	{ description, type, position }: ToastOptions,
) => {
	const id = GenerateUUID();

	const toast: HTMLLIElement = document.createElement('li');
	list.prepend(toast);

	const icons: ToastIcon = {
		success: 'check',
		warning: 'warning',
		danger: 'error',
		info: 'bell',
		default: 'bulb',
	};

	const colors: ToastColor = {
		success: 'text-green-600 dark:text-green-400',
		warning: 'text-amber-600 dark:text-amber-400',
		danger: 'text-red-600 dark:text-red-400',
		info: 'text-blue-600 dark:text-blue-400',
		default: 'text-orange-600 dark:text-orange-400',
	};

	const borders: ToastColor = {
		success: 'border-green-600 dark:border-green-400',
		warning: 'border-amber-600 dark:border-amber-400',
		danger: 'border-red-600 dark:border-red-400',
		info: 'border-blue-600 dark:border-blue-400',
		default: 'border-orange-600 dark:border-orange-400',
	};

	toast.outerHTML = `<li class='toast px-3 py-4 flex flex-col items-start border border-dashed ${borders[type!] || borders.default} bg-background rounded-md transition-all duration-300 ease-out' data-id='${id}' data-removed='false' data-mounted='false' data-front='true' data-expanded='false' data-index='${0}' data-y-position='${list.getAttribute('data-y-position') ?? position!.split('-')[0]}' data-x-position='${list.getAttribute('data-x-position') ?? position!.split('-')[1]}' style='--index: 0; --toasts-before: ${0}; --z-index: ${list.children.length}; --toast-offset: 0px; --initial-height: 0px;'>
                            <div class='w-full flex items-center gap-x-3 ${colors[type!] || colors.default}'>
                                <lord-icon class='max-[376px]:size-6 size-7 shrink-0 current-color' trigger='loop' src='/assets/lottie/${icons[type!] || icons.default}.json'></lord-icon>
								<div class='flex flex-col gap-y-0.5'>
                                    <h3 class='max-[376px]:text-xs text-[14.5px] !font-medium !text-foreground'>
                                        ${title}
                                    </h3>
                                    <p class='max-[376px]:!text-xs !text-sm text-muted-foreground'>
                                        ${description}
                                    </p>
                                </div>
                                <sl-icon data-toaster='toast-close-button' class='text-muted-foreground size-5 ms-auto max-[376px]:hidden' library='heroicons' name='x-mark'></sl-icon>
                            </div>
                       </li>`;

	return { toast, id };
};

const refreshProperties = () => {
	const list = ref("[data-toaster='list']") as HTMLOListElement;
	if (!list) return;

	let heightsBefore = 0;
	let removed = 0;

	for (let i = 0; i < list.children.length; i += 1) {
		const el = list.children[i] as HTMLLIElement;

		if (el.getAttribute('data-removed') === 'true') {
			removed += 1;
			continue;
		}

		const idx = i - removed;

		el.setAttribute('data-index', `${idx}`);
		el.setAttribute('data-front', idx === 0 ? 'true' : 'false');
		el.setAttribute('data-visible', idx < VISIBLE_TOASTS_AMOUNT ? 'true' : 'false');

		el.style.setProperty('--index', `${idx}`);
		el.style.setProperty('--toasts-before', `${idx}`);
		el.style.setProperty('--toast-offset', `${GAP * idx + heightsBefore}px`);
		el.style.setProperty('--z-index', `${list.children.length - i}`);

		heightsBefore += Number(el.getAttribute('data-initial-height'));
	}
};

const registerRemoveTimeout = (el: HTMLLIElement) => {
	const tid = window.setTimeout(() => {
		remove(el);
	}, TOAST_LIFETIME);

	el.setAttribute('data-remove-tid', `${tid}`);
};

const clearRemoveTimeout = (el: HTMLLIElement) => {
	const tid = el.getAttribute('data-remove-tid');

	if (tid != null) {
		window.clearTimeout(+tid);
	}
};

const remove = (el: HTMLLIElement) => {
	el.setAttribute('data-removed', 'true');
	refreshProperties();

	const tid = window.setTimeout(() => {
		el.parentElement?.removeChild(el);
	}, TIME_BEFORE_UNMOUNT);
	el.setAttribute('data-unmount-tid', `${tid}`);
};

const closeToast = (el: HTMLLIElement) => {
	const button = ref("[data-toaster='toast-close-button']", el) as SlIcon;
	if (!button) return;

	listener(button, 'click', () => remove(el));
};

const enableSwipeToDismiss = (toast: HTMLLIElement) => {
	let startTouch: TouchPoint | undefined;
	let isDragging = false;
	let swipeDirection: 'x' | 'y' | undefined;

	const moveToast = (distanceX: number, distanceY: number) => {
		requestAnimationFrame(() => {
			if (swipeDirection === 'x') {
				toast.style.transform = `translateX(${distanceX}px)`;
			} else if (swipeDirection === 'y') {
				toast.style.transform = `translateY(${distanceY}px)`;
			}

			const distance = swipeDirection === 'x' ? distanceX : distanceY;
			toast.style.opacity = `${1 - Math.abs(distance) / 100}`;
		});
	};

	const resetToastPosition = () =>
		requestAnimationFrame(() => {
			toast.style.transform = '';
			toast.style.opacity = '';
		});

	const determineSwipeDirection = (distanceX: number, distanceY: number) => {
		if (!swipeDirection) {
			swipeDirection = Math.abs(distanceX) > Math.abs(distanceY) ? 'x' : 'y';
		}
	};

	const onStart = (point: TouchPoint) => {
		startTouch = point;
		isDragging = true;
		swipeDirection = undefined;
	};

	const onEnd = (point: TouchPoint) => {
		if (!startTouch || !isDragging) return;

		const distanceX = point.x - startTouch.x;
		const distanceY = point.y - startTouch.y;
		const swipeThreshold = 50;

		if (swipeDirection === 'x' && Math.abs(distanceX) > swipeThreshold) {
			setTimeout(() => remove(toast), 300);
		} else if (swipeDirection === 'y' && Math.abs(distanceY) > swipeThreshold) {
			setTimeout(() => remove(toast), 300);
		} else {
			resetToastPosition();
		}

		startTouch = undefined;
		isDragging = false;
	};

	toast.addEventListener('touchstart', (event) => {
		const touch = event.touches[0];
		onStart({ x: touch.screenX, y: touch.screenY });
	});

	toast.addEventListener('touchmove', (event) => {
		if (!startTouch || !isDragging) return;

		const touch = event.touches[0];
		const currentTouch = { x: touch.screenX, y: touch.screenY };
		determineSwipeDirection(currentTouch.x - startTouch.x, currentTouch.y - startTouch.y);
		moveToast(currentTouch.x - startTouch.x, currentTouch.y - startTouch.y);
		event.preventDefault();
	});

	toast.addEventListener('touchend', (event) => {
		const touch = event.changedTouches[0];
		onEnd({ x: touch.screenX, y: touch.screenY });
	});

	toast.addEventListener('mousedown', (event) => {
		onStart({ x: event.screenX, y: event.screenY });
	});

	toast.addEventListener('mousemove', (event) => {
		if (!startTouch || !isDragging) return;

		const currentTouch = { x: event.screenX, y: event.screenY };
		const distanceX = currentTouch.x - startTouch.x;
		const distanceY = currentTouch.y - startTouch.y;

		moveToast(distanceX, distanceY);
		event.preventDefault();
	});

	toast.addEventListener('mouseup', (event) => {
		onEnd({ x: event.screenX, y: event.screenY });
		resetToastPosition();
	});

	return () => {
		toast.removeEventListener('touchstart', <any>onStart);
		toast.removeEventListener('touchmove', <any>onStart);
		toast.removeEventListener('touchend', <any>onEnd);
		toast.removeEventListener('mousedown', <any>onStart);
		toast.removeEventListener('mousemove', <any>onStart);
		toast.removeEventListener('mouseup', <any>onEnd);
	};
};
