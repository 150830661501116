import type { SlButton, SlIconButton } from '@shoelace-style/shoelace';

type Selector = SlButton | SlIconButton | HTMLButtonElement | HTMLElement;
const setLoadingState = (element: Selector | null | undefined, isLoading: boolean): void => {
	if (!element) {
		console.warn('setLoadingState: Element is null or undefined.');
		return;
	}
	element.toggleAttribute('loading', isLoading);
};

export default setLoadingState;
